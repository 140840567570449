/* import __COLOCATED_TEMPLATE__ from './answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import PALETTE from '@intercom/pulse/lib/palette';
import {
  CustomerAnswerRating,
  Status,
  type PlaygroundQuestion,
} from 'embercom/lib/fin-playground/question';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from 'embercom/lib/knowledge-hub/constants';
import { EntityType } from 'embercom/models/data/entity-types';

interface FinAnswerComponentArgs {
  appId: string;
  question?: PlaygroundQuestion;
  generateAnswer: () => void;
  playgroundIsLoading: boolean;
}

type FinAnswerComponentSignature = {
  Args: FinAnswerComponentArgs;
  Element: HTMLDivElement;
};

export default class AiAgentPlaygroundFinAnswer extends Component<FinAnswerComponentSignature> {
  @tracked bottomOfAnswerVisible = false;
  @tracked scrollToBottomOfAnswer = false;

  CustomerAnswerRating = CustomerAnswerRating;
  Status = Status;
  EntityType = EntityType;

  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  get answer() {
    return this.args.question?.responseText;
  }

  get dotColor() {
    return `background-color: ${PALETTE['neutral-fill-emphasis']};`;
  }

  @action setScrollToBottomOfAnswer(visible: boolean) {
    this.scrollToBottomOfAnswer = visible;
  }

  @action setBottomOfAnswerVisible(visible: boolean) {
    this.bottomOfAnswerVisible = visible;
  }

  get triggeredWorkflowUrl() {
    let triggeredWorkflow = this.args.question?.triggeredWorkflow;

    if (!triggeredWorkflow) {
      throw new Error('No triggered workflow found');
    }

    if (this.appService.app.isStandaloneApp) {
      return this.router.urlFor('apps.app.standalone.tasks.workflow', triggeredWorkflow.rulesetId);
    }

    return this.router.urlFor(
      'apps.app.automation.fin-ai-agent.tasks.task',
      triggeredWorkflow.rulesetId,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::Answer': typeof AiAgentPlaygroundFinAnswer;
  }
}
