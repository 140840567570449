/* import __COLOCATED_TEMPLATE__ from './playground-heading.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import FinPlaygroundExport from 'embercom/lib/fin-playground-test-export';
import type { Playground } from 'embercom/lib/fin-playground/playground';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import type CsvService from 'embercom/services/csv';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';

interface PlaygroundHeadingArgs {
  canUsePlaygroundGroups: boolean;
  isPlaygroundLocked: boolean;
  showResetModal: boolean;
  updateShowConfirmTestRunModal: (showConfirmTestRunModal: boolean) => void;
  showExceedingQuestionLimitBanner: boolean;
  updateShowExceedingQuestionLimitBanner: (showExceedingQuestionLimitBanner: boolean) => void;
  updateShowTestSettingsModal: (showTestSettingsModal: boolean) => void;
  updateShowResetModal: (showResetModal: boolean) => void;
  addQuestionDropdownOptions: {
    items: { text: string; value: string; isDisabled?: boolean }[];
  }[];
  selectAddQuestionDropdownOption: (option: string) => void;
  shouldUseTestSettingsModal: boolean;
  handlePlaygroundSettingsChanged: () => void;
  isDetailsModuleOpen: boolean;
  playgroundIsLoading: boolean;
  playground: Playground;
  teammateHasNoAccessToAllConversations: boolean;
}

const SURVEY_ID = 45346448;

export default class PlaygroundHeading extends Component<PlaygroundHeadingArgs> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare csv: CsvService;
  @service declare intercomEventService: $TSFixMe;
  @service declare aiContentSegmentsService: AiContentSegmentsService;

  get isAddQuestionDropdownDisabled() {
    return (
      this.args.isPlaygroundLocked ||
      this.args.playgroundIsLoading ||
      this.args.playground.hasReachedQuestionLimit
    );
  }

  get runTestButtonLabel(): string {
    return this.args.playground.isRunning
      ? this.intl.t('ai-agent.playground.running-test')
      : this.intl.t('ai-agent.playground.run-test');
  }

  get deductionRequiredToAddQuestion(): number {
    if (!this.args.playground.hasReachedQuestionLimit) {
      return 0;
    }

    return 1 + this.args.playground.questions.length - this.args.playground.questionLimit;
  }

  get canShowHeaderButtons(): boolean {
    return this.args.playground.hasQuestions && !this.args.teammateHasNoAccessToAllConversations;
  }

  @action
  startSurvey() {
    startSurvey(SURVEY_ID);
  }

  @action
  downloadResults() {
    let { data, fileName } = new FinPlaygroundExport(
      this.appService.app,
      this.intl,
      this.args.playground,
      this.router,
      this.aiContentSegmentsService.segments,
    );

    this.csv.export(data, {
      fileName,
      withSeparator: false,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'downloaded_results',
      object: 'playground',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::PlaygroundHeading': typeof PlaygroundHeading;
    'ai-agent/playground/playground-heading': typeof PlaygroundHeading;
  }
}
